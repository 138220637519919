<template>
  <div class="section-lesson">
    <div class="mb-4">
      <h4 class="">Section dan Lesson</h4>
    </div>
    <div class="d-flex justify-content-between">
      <div class="w-100">
        <a-input placeholder="Masukkan nama section untuk tambah section..." v-model:value="newSection.name"></a-input>
      </div>
      <div class="ml-3">
        <a-button :disabled="!newSection.name" @click.prevent="addSection" type="primary">
          <plus-outlined />
        </a-button>
      </div>
    </div>
    <a-collapse class="mt-3" v-model:activeKey="activeKeySectionAccordion" v-if="sectionList.length" expand-icon-position="left">
      <a-collapse-panel :disabled="isEdit && idEdit !== section._id" v-for="(section) in sectionList" :key="String(section._id)">
        <template #header>
          <template v-if="isEdit && idEdit === section._id">
            <a-input :default-value="section.name" @change="e => changeSection(e, section)" class="w-75"></a-input>
          </template>
          <template v-else>
            {{section.name}}
          </template>
        </template>
        <template #extra>
          <div v-if="isEdit && idEdit === section._id">
            <a-button @click.prevent="saveEdit" type="link" class="text-success">Simpan</a-button>
            <a-divider type="vertical" class="mx-1"></a-divider>
            <a-button @click.prevent="cancelEdit" class="text-danger" type="link">Batal</a-button>
          </div>
          <div v-else>
            <a-dropdown :trigger="['click']">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1">
                    <div @click.prevent="editSection(section)">
                      <edit-outlined></edit-outlined>
                      Edit {{section.name}}
                    </div>
                  </a-menu-item>
                  <a-menu-item key="2" class="text-danger">
                    <div @click.prevent="deleteSection(section)">
                      <delete-outlined></delete-outlined>
                      Hapus {{section.name}}
                    </div>
                  </a-menu-item>
                </a-menu>
              </template>
              <div class="h-100">
                <setting-outlined></setting-outlined>
                Aksi
              </div>
            </a-dropdown>
          </div>
        </template>
        <div class="mb-3" v-if="section.lessons.length">
          <template v-for="(lesson, j) in section.lessons" :key="j">
            <a-row type="flex" align="middle" :gutter="[16, 0]" class="d-flex w-100">
              <a-col :span="3">
                <div v-if="isEdit && idEdit === section._id">
                  <a-select :default-value="lesson.isLocked ? 'true' : 'false'" @change="e => changeLesson(e, lesson, 'isLocked')">
                    <a-select-option value="true">Dikunci</a-select-option>
                    <a-select-option value="false">Terbuka</a-select-option>
                  </a-select>
                </div>
                <template v-else>
                  <div class="font-size-16">
                    <a-tag :color="lesson.isLocked ? '#f50' : '#87d068'">
                      <lock-outlined v-if="lesson.isLocked"></lock-outlined>
                      <unlock-outlined v-else></unlock-outlined>
                    </a-tag>
                  </div>
                </template>
              </a-col>
              <a-col :span="isEdit && idEdit === section._id ? 21 : 11">
                <div class="w-100" v-if="isEdit && idEdit === section._id">
                  <a-input :default-value="lesson.name" @change="e => changeLesson(e, lesson, 'name')"></a-input>
                </div>
                <template v-else>
                  {{lesson.name}}
                </template>
              </a-col>
              <a-col v-if="idEdit !== section._id" class="d-flex justify-content-center" :span="8">
                <a-upload
                  v-if="!lesson.videoUrl"
                  accept="video/mp4"
                  name="fileLesson"
                  :action="`${backendUrl}api/course/upload-lesson/${lesson._id}`"
                  :headers="{
                    authorization: 'authorization-text',
                  }"
                  :custom-request="customRequest"
                >
                  <a-button>
                    <upload-outlined></upload-outlined>
                    Unggah Video
                  </a-button>
                </a-upload>
                <div v-else class="d-flex justify-content-center align-items-center">
                  <a-button @click="showVideo(section.name, lesson)" type="link">
                    <eye-outlined></eye-outlined>
                     Lihat Video</a-button>
                  <a-modal
                    width="800"
                    :title="`Video ${videoLessonActive.sectionName} Lesson ${videoLessonActive.name}`"
                    :visible="modalVideoShow"
                    @cancel="hideModalVideo" centered>
                    <div class="mb-3 text-center">
                      <a-button @click.prevent="deleteVideo(videoLessonActive)" type="danger">
                        <delete-outlined></delete-outlined>
                        Hapus Video
                      </a-button>
                    </div>
                    <div class="w-100 text-center">
                      <video
                        class="video-course"
                        style="max-width: 800px;"
                        autoplay
                        controls
                        playsinline
                        :data-poster="videoLessonActive.poster ? '' : ''"
                      >
                        <source
                          :src="videoLessonActive.videoUrl ? `${backendUrl}${videoLessonActive.videoUrl}` : '/video/video1.mp4'"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <template #footer>
                      <div></div>
                    </template>
                  </a-modal>
                  <a-divider type="vertical" class="mx-2"></a-divider>
                </div>
              </a-col>
              <a-col v-if="idEdit !== section._id" :span="2" class="text-right">
                <a-button @click.prevent="deleteLesson(lesson, section.name)" type="default" class="text-danger">
                  <delete-outlined></delete-outlined>
                </a-button>
              </a-col>
            </a-row>
            <a-divider v-if="j !== section.lessons.length - 1" class="my-3"></a-divider>
          </template>
        </div>
        <div class="text-center" v-else>Belum Ada Lesson.</div>
        <template v-if="isEdit && idEdit === section._id">
          <div class="mt-3">
            <a-button block @click.prevent="addLesson(section.key)" type="dashed">
              <plus-outlined /> Buat Lesson
            </a-button>
          </div>
          <!-- <a-divider class="my-3"></a-divider>
          <a-row type="flex" align="middle" :gutter="[16, 0]" class="d-flex w-100">
            <a-col :span="3">
              <div>
                <a-select v-model:value="newLesson.isLocked">
                  <a-select-option value="true">Dikunci</a-select-option>
                  <a-select-option value="false">Terbuka</a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="11">
              <div class="w-100">
                <a-input v-model:value="newLesson.name"></a-input>
              </div>
            </a-col>
            <a-col class="d-flex justify-content-end" :span="10">
              <a-button @click.prevent="addLesson(section.key)" type="dashed">
                <plus-outlined /> Buat Lesson
              </a-button>
            </a-col>
          </a-row> -->
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRef, toRefs, watch, createVNode } from 'vue'
import { useStore } from 'vuex'
import { PlusOutlined, LockOutlined, UploadOutlined, EyeOutlined, SettingOutlined, DeleteOutlined, ExclamationCircleOutlined, UnlockOutlined, EditOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { Modal, notification } from 'ant-design-vue';
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    slots: { customRender: 'name' },
  },
  {
    title: 'Video',
    align: 'center',
    width: 600,
    children: [
      {
        title: 'Durasi',
        dataIndex: 'duration',
        key: 'duration',
        width: 200,
        slots: { customRender: 'empty' },
        align: 'center',
      },
      {
        title: 'Filename',
        dataIndex: 'filename',
        key: 'filename',
        slots: { customRender: 'empty' },
        width: 200,
        align: 'center',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        slots: { customRender: 'action' },
        align: 'center',
      },
    ],
  },
]

export default {
  components: { PlusOutlined, LockOutlined, UploadOutlined, SettingOutlined, DeleteOutlined, UnlockOutlined, EyeOutlined, EditOutlined },
  props: {
    course: {
      type: Object,
      default: () => { return {} },
    },
  },
  emits: ['fetchCourse'],
  setup(props, { emit }) {
    const { course: courseData } = toRefs(props)
    const store = useStore()
    const route = useRoute()
    const idCourse = route.params.id
    let vendors = ref([])
    let categories = ref([])
    let courseGeneralForm = reactive({
      name: null,
      description: null,
      price: 0,
      type: 'free',
      vendorId: null,
      categoryId: null,
    })

    const isEdit = ref(false)

    let sectionList = ref(courseData.value.sections.map(section => {
      const lessons = section.lessons.map(lesson => {
        if(lesson.videoUrl) {
          const lessonImageUrlArr = lesson.videoUrl.split('/')
          lesson.filename = lessonImageUrlArr[lessonImageUrlArr.length - 1]
        }
        return {
          ...lesson,
          idSection: section._id,
        }
      })
      return {
        ...section,
        lessons,
      }
    }))

    watch(courseData, (newVal) => {
      // console.log(sectionList.value, courseData.value, 'fksdfoso')
      // console.log(newVal)
      sectionList.value = newVal.sections.map(section => {
        const lessons = section.lessons.map(lesson => {
          if(lesson.videoUrl) {
            const lessonImageUrlArr = lesson.videoUrl.split('/')
            lesson.filename = lessonImageUrlArr[lessonImageUrlArr.length - 1]
          }
          return {
            ...lesson,
            idSection: section._id,
          }
        })
        return {
          ...section,
          lessons,
        }
      })
    })

    // sectionList.value = courseData.value.sections.map(section => {
    //   const lessons = section.lessons.map(lesson => {
    //     if(lesson.videoUrl) {
    //       const lessonImageUrlArr = lesson.videoUrl.split('/')
    //       lesson.filename = lessonImageUrlArr[lessonImageUrlArr.length - 1]
    //     }
    //     return {
    //       ...lesson,
    //       idSection: section._id,
    //     }
    //   })
    //   return {
    //     ...section,
    //     lessons,
    //   }
    // })

    let newSection = reactive({
      name: null,
    })

    const hideModalVideo = () => {
      modalVideoShow.value = false
      videoLessonActive.value = {}
      const videos = document.getElementsByClassName('video-course')
      videos.forEach(video => {
        video.pause()
      })
    }

    let newLesson = reactive({
      name: null,
      isLocked: "false",
    })

    let editedSection = reactive({
      name: null,
      lessons: [],
    })

    let idEdit = ref(null)

    let activeKeySectionAccordion = ref([])
    let modalVideoShow = ref(false)

    let videoLessonActive = ref({})

    onMounted(async () => {
    })

    const showVideo = (sectionName, lesson) => {
      videoLessonActive.value = {
        sectionName,
        ...lesson,
      }
      modalVideoShow.value = true
    }

    const addSection = async () => {
      // console.log(sectionList.value, vendors.value[0])
      Modal.confirm({
        title: `Apa anda yakin ingin menambah data section?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const data = await store.dispatch('course/ADD_SECTION_COURSE', { idCourse, section: newSection })
            isEdit.value = false
            notification.success({
              message: `Berhasil Menambah Section.`,
            })
            emit('fetchCourse')
            newSection.name = null
          } catch (err) {
            console.log(err)
          }
        },
        onCancel() {},
      })
    }

    const deleteLesson = (lesson, sectionName) => {
      Modal.confirm({
        title: `Apa anda yakin ingin menghapus lesson '${lesson.name}' pada section '${sectionName}'?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const data = await store.dispatch('course/DELETE_LESSON', { idCourse, idSection: lesson.idSection, idLesson: lesson._id })

            notification.success({
              message: `Berhasil Menghapus Lesson ${lesson.name} pada section '${sectionName}'.`,
            })
            emit('fetchCourse')
          } catch (err) {
            console.log(err)
            notification.error({
              message: `Gagal Menghapus Lesson ${lesson.name} pada section '${sectionName}'.`,
            })
          }
        },
        onCancel() {},
      })
    }

    const addLesson = (idSection) => {
      editedSection.lessons = editedSection.lessons.concat([{
        key: editedSection.lessons.length,
        name: null,
        isLocked: false,
      }])
      sectionList.value = sectionList.value.map(section => {
        if (section._id === idSection) {
          section.lessons = section.lessons.concat([{
            key: sectionList.value.length,
            name: null,
            isLocked: false,
          }])
          return section
        }
        return section
      })
      newLesson = {
        name: null,
        isLocked: "false",
      }
    }

    const editSection = (section) => {
      isEdit.value = true
      idEdit.value = section._id
      activeKeySectionAccordion.value = section._id
      editedSection = section
    }

    const deleteSection = (payload) => {
      Modal.confirm({
          title: `Apa anda yakin ingin menghapus section '${payload.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('course/DELETE_SECTION_COURSE', { idCourse, idSection: payload._id })
              isEdit.value = false

              notification.success({
                message: `Berhasil Menghapus Section ${payload.name}.`,
              })
              emit('fetchCourse')
            } catch (err) {
              console.log(err)
              notification.error({
                message: `Gagal Menghapus Section ${payload.name}.`,
              })
            }
          },
          onCancel() {},
        })
    }

    const saveEdit = async () => {
      try {
        const payload = editedSection
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada section '${payload.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('course/UPDATE_SECTION_COURSE', { section: payload, idCourse })
              isEdit.value = false
              idEdit.value = null

              notification.success({
                message: `Berhasil Mengubah Data Pada Section.`,
              })
              emit('fetchCourse')
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const backendUrl = process.env.VUE_APP_BACKEND_URL

    const handleChange = info => {
      if (info.file.status !== 'uploading') {
      }

      if (info.file.status === 'done') {
        message.success(`file ${info.file.name} berhasil diunggah.`);
        fileList.value = []
        emit('fetchCourse')
      } else if (info.file.status === 'error') {
        message.error(`file ${info.file.name} gagal diunggah.`);
        fileList.value = []
        emit('fetchCourse')
      }
    };

    onMounted(async () => {
    })

     const fileList = ref([]);

    const dataTableLessons = (lessons) => {
      return lessons.map(lesson => {
        return {
          key: lesson._id,
          ...lesson,
        }
      })
    }

    const sendVideo = (file, record) => {

      const formData = new FormData();
      fileList.value.forEach(list => {
        formData.append('files[]', list);
      });

      return apiClient.post(`${backendUrl}api/course/upload-lesson/${record._id}`, { formData })
    }

    const deleteVideo = async (lesson) => {
      const { idSection, _id:idLesson, videoUrl, filename } = lesson
      try {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus video pada lesson '${lesson.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('course/DELETE_VIDEO_LESSON', { idCourse, idSection, idLesson, filename: videoUrl })
              modalVideoShow.value = false
              videoLessonActive.value = {}
              // message.success(`file ${filename} berhasil dihapus`);
              emit('fetchCourse')

              notification.success({
                message: `Berhasil Menghapus Video Pada Lesson '${lesson.name}'.`,
              })
              emit('fetchCourse')
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        console.log(err)
        message.error(`Gagal Menghapus Video Pada Lesson '${payload.name}'.`);
        emit('fetchCourse')
      }
    }

    const changeLesson = (e, lesson, key) => {
      const newObj = {}
      if (key === 'isLocked') newObj[key] = JSON.parse(e)
      else newObj[key] = e.target.value
      editedSection.lessons = editedSection.lessons.map(editedLesson => {
        // console.log(editedLesson._id, lesson._id, editedLesson._id !== undefined && lesson._id !== undefined, 'ini data editedLesson')
        if ((editedLesson._id !== undefined && lesson._id !== undefined && editedLesson._id === lesson._id)) {
          return {
            ...editedLesson,
            ...newObj,
          }
        } else if ((typeof editedLesson.key === 'number' && typeof lesson.key === 'number' && editedLesson.key === lesson.key)) {
          return {
            ...editedLesson,
            ...newObj,
          }
        } else return editedLesson
      })
      // console.log(editedSection.lessons, 'change')
    }


    const changeSection = (e, section) => {
      const { value } = e.target
      if (editedSection._id === section._id) {
        editedSection.name = value
      }
    }

    const cancelEdit = () => {
      emit('fetchCourse')
      sectionList.value = sectionList.value.map(section => {
        // console.log(section)
        if (section._id === idEdit.value) {
          section.lessons = section.lessons.filter(lesson => !lesson.key)
          return section
        }
        return section
      })
      isEdit.value = false
      idEdit.value = null
    }

    const beforeUpload = async file => {
      try {
        const isMp4 = file.type === 'video/mp4';

        file.coba = 'tesss'
        // console.log(file)
        if (!isMp4) {
          message.error('Harap hanya mengunggah file video berformat MP4.');
        }
        return isMp4;
      } catch(err) {
        console.log(err)
      }
    };

    const customRequest = async (data) => {
      try {
        const isPassed = await beforeUpload(data.file)
        // console.log(isPassed,'ksao')
        if (!isPassed)  {
          return ''
        } else {
          if (data.file.status !== 'uploading') {
          }
          const formData = new FormData()
          formData.append(data.filename, data.file)
          const { data: { data:res } } = await apiClient.post(data.action, formData, {
            ...data.headers,
          })

          const video = document.createElement("VIDEO")
          // console.log(res, video)
          // console.log(res.videoUrl.split('/').join('/'))
          video.src = `${backendUrl}${res.videoUrl}`
          video.addEventListener('loadedmetadata', async () => {
            const videoDuration = video.duration
            store.dispatch('course/UPDATE_VIDEO_METADATA', { payload: { videoDuration }, idLesson: res._id })
          });

          message.success(`file ${data.file.name} berhasil diunggah.`);
          fileList.value = []
        }
        emit('fetchCourse')
      } catch (err) {
        message.error(`file ${data.file.name} gagal diunggah.`);
        fileList.value = []
        emit('fetchCourse')
        console.log(err)
      }
    }

    return {
      vendors,
      categories,
      courseGeneralForm,
      newSection,
      addSection,
      sectionList,
      activeKeySectionAccordion,
      addLesson,
      isEdit,
      idEdit,
      courseData,
      editSection,
      saveEdit,
      columns,
      dataTableLessons,
      handleChange,
      fileList,
      backendUrl,
      sendVideo,
      deleteVideo,
      changeLesson,
      editedSection,
      cancelEdit,
      newLesson,
      deleteSection,
      changeSection,
      deleteLesson,
      showVideo,
      videoLessonActive,
      modalVideoShow,
      hideModalVideo,
      beforeUpload,
      customRequest,
    }
  },
}
</script>

<style lang="scss">
.section-lesson {
  .ant-table td {
    word-wrap: break-word;
  }
}
</style>
